export const Home = () => {
  return (
    <section className="h-screen wrapper">
      {/* this is the header */}
      <div className="">
        <section className="">
          <img
            src="https://lazemms.sa/_next/static/media/LogoDark.0670a20c.png"
            className=" h-32 w-32 mx-auto mt-3"
            alt="Lazem Logo"
          />
        </section>
        <section className="flex flex-col mx-auto main-section">
          <a href="https://asset.lazem.sa/assets/profiles/Lazem_Profile_EN.pdf" className="text-white mx-auto bg-cyan-800 border-2 rounded-md w-fit p-4 h-fit btn">
            Lazem Group Profile
          </a >
          <a href="https://asset.lazem.sa/assets/profiles/Lazem_Profile_AR.pdf" className="text-white mx-auto bg-cyan-800 border-2 rounded-md w-fit p-4 h-fit btn">
            الملف التعريفي لمجموعة لازم
          </a>
        </section>

        {/* <section className="w-screen h-min border-2 border-t-black p-6 ">
          <div className="text-center">
            <h1>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maiores
              soluta voluptatibus libero vel sunt modi nulla delectus quod quo
              velit facilis voluptatum, neque, deserunt nihil suscipit? Odit
              nostrum iusto omnis doloribus, iste fugit veniam a voluptatum quas
              eveniet tenetur corrupti saepe! Unde itaque, reiciendis corrupti
              ducimus asperiores deserunt veritatis fugiat! Autem rerum eaque
              impedit sapiente consequuntur deleniti voluptate. Facere veniam
              illum maiores architecto consequatur dolore deserunt minima alias.
            </h1>
          </div>
        </section> */}
      </div>
    </section>
  );
};
